import * as React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
      <p>
          Built by Hugo Moran
      </p>
  </footer>


    
)


export default Footer
